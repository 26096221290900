@import '../mixins';

/*
 * General styling to make detail/summary tags look a bit more material
 * To get the best out of it you should structure your usage like this:
 *
 * ```
 * <details>
 *   <summary>Some title</summary>
 *   <div class="details-content">
 *     Some content
 *   </div>
 *  </details>
 *
 */

summary {
  cursor: pointer;
  @include typescale-default;
  position: relative;
  padding: 16px 24px;
  color: $black;
  height: 16px;
  display: block; // Remove the built in details marker in FF

  &::-webkit-details-marker {
    display: none; // Remove the built in details marker in webkit
  }

  &::before {
    content: '\E5CE'; // See https://material.io/icons/#ic_expand_less
    font-family: 'Material Icons';
    @include typescale-xlarge;
    -webkit-font-smoothing: antialiased;
    @include rotate(0deg); // We will rotate 180 degrees when details is open
    float: right;
  }
}

details {
  box-shadow: 0 1px 4px 0 rgba($black, 0.37);

  .detail-contents {
    padding: 16px 24px;
  }

  &[open] > summary::before {
    @include rotate(180deg); // Rotate the icon
  }
}
