@import '../mixins';

label.raised, .api-header label {
    border-radius: 4px;
    padding: 4px 16px;
    display: inline;
    @include typescale-default;
    color: white;
    margin-right: 8px;
    font-weight: 500;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
        display: block;
        margin: 8px 0;
    }

    &.page-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $mist;
        color: $mediumgray;
        margin-bottom: 8px;
        width: 140px;

        .material-icons {
            margin-right: 8px;
        }
    }

    &.property-type-label {
      @include typescale-small;
        background-color: $darkgray;
        color: $white;
        text-transform: none;
    }
}

.api-header label {

    // The API badges should be a little smaller
    padding: 2px 10px;
    @include typescale-small;

    @media screen and (max-width: 600px) {
        margin: 4px 0;
    }

    &.api-status-label {
        background-color: $mediumgray;

        &.impure-pipe {
            background-color: $brightred;
        }
    }

    &.api-type-label {
        background-color: $accentblue;

        @each $name, $symbol in $api-symbols {
            &.#{$name} {
                background: map-get($symbol, background);
            }
        }

    }
}
