@import '../mixins';

.alert {
    padding: 16px;
    margin: 24px 0px;
    @include typescale-default;
    color: $darkgray;
    width: 100%;
    box-sizing: border-box;
    clear: both;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
    }

    &.is-critical {
        border-left: 8px solid $brightred;
        background-color: rgba($brightred, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $brightred;
        }
    }

    &.is-important {
        border-left: 8px solid $orange;
        background-color: rgba($orange, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $orange;
        }
    }

    &.is-helpful {
        border-left: 8px solid $purple;
        background-color: rgba($purple, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $purple;
        }
    }

    > * {
        margin: 8px 16px;
    }
}
